/*wTxt.scss*/
@import "../_config";
/*----------------------------------- wTxt -----------------------------------*/
.wTxt {
    text-align: left;
    text-decoration: none;
    position: relative;
    color: #8c8c8c;
    text-transform: none;
    font-family: 'Akkurat', Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    line-height: 26px;
    margin: 0 0 $emSizeText;

    a {
        text-decoration: underline;
        color: $colorLink;
        &:visited {text-decoration: inherit; color: $colorLinkVisited; }
        &:hover {text-decoration: underline; color: $colorLinkHover; }
        &:active {text-decoration: underline; color: $colorActive; }
        &[href^="http"], &[href^="www."] {cursor: pointer; cursor: alias; }
    }

    h1, h2, h3, h4, h5, h6 {
        line-height: $emSizeHeader;
        margin: $emSize 0 $emSizePart;
        a {text-decoration: none;}
        &:before {content: ''; display: block; clear: both; height: 0; }
        &:first-child {margin-top: 0; }
    }
    h1 {font-size: $fontSizeH1; }
    h2 {font-size: $fontSizeH1 * 0.9; }
    h3 {font-size: $fontSizeH1 * 0.8; }
    h4 {font-size: $fontSizeH1 * 0.7; }
    h5 {font-size: $fontSizeH1 * 0.6; }
    h6 {font-size: $fontSizeH1 * 0.5; }

    ul, ol, dl, pre, p, table, blockquote, address, .iframeHolder {
        margin: $emSizeText 0;
        &:first-child {margin-top: 0; }
        &:last-child {margin-bottom: 0; }
    }
    blockquote {
        padding: $emSizeText;
        font-style: italic;
        background-color: #f6f6f6;
        border-left: 3px solid #d8d8d8;
    }

    ul, ol, dl, dd {
        margin-left: 0;
    }
    ul, ol {
        ul, ol {
            margin-top: 0;
        }
    }
    li {
        list-style-position: inside;
        margin-left: 12px;
    }
    ul, ol {
        li {
            list-style-type: inherit;
        }
    }
    ul {
        list-style-type: disc;
        ul {
            list-style-type: circle;
            ul {
                list-style-type: square;
            }
        }
    }
    ol {
        list-style-type: decimal;
    }
    ul {
        list-style: none;
        li {
            position: relative;
            &:after {
                position: absolute;
                content: '';
                left: -12px;
                top: 8px;
                height: 4px;
                width: 4px;
                border-radius: 4px;
                background: #cb2228;
            }
        }
    }
    dl dt {
        font-style: italic;
    }

    table  {
        width: 100%;
        background-color: transparent;
        border-collapse: collapse;
        border-spacing: 0;
        border: $borderTable;
        caption {
            margin-bottom: $emSizeHalf;
            caption-side: top;
        }
        tbody, tfoot, thead,
        tr, th, td {
            border: inherit;
        }
        th, td {
           padding: 10px;
           line-height: $emSize;
           text-align: left;
           vertical-align: top;
        }
        th {
            text-align: center;
            font-weight: bold;
            background-color: $colorLight;
        }
        &.table-null,
        &.table-null th,
        &.table-null td {
            border-color: transparent;
        }
        &.table-zebra,
        &.table-zebra th,
        &.table-zebra td {
            border-color: transparent;
        }
        &.table-zebra tr:nth-child(even) {
            background-color: $colorMiddleLight;
        }
    }

    pre, code, kbd, samp {
        font-style: normal;
        border-radius: $borderRadius;
        border: 1px solid #777;
        background-color: $colorLight;
    }
    pre {
        line-height: $emSizeText;
        tab-size: 4;
        padding: $emSize;
    }
    code, kbd, samp {
        letter-spacing: 1px;
        color: $colorWhite;
        text-shadow: 0 0 2px rgba($colorBlack, .5);
        line-height: $emSizeHeader;
        font-weight: bold;
        display: inline-block;
        vertical-align: inherit;
        padding: 0 $emSize * 0.375;
    }
    code {background-color: darken($colorPrimary, 5%); }
    kbd {background-color: darken($colorSucces, 5%); }
    samp {background-color: darken($colorInfo, 10%); }

    audio {
        display: block;
        width: 100%;
    }

}

.iframeHolder {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    > iframe, > video {
        background: $colorBlack;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 740px) {
    $fontSizeH1: 30px;
    .wTxt {
        h1 {font-size: $fontSizeH1; }
        h2 {font-size: $fontSizeH1 * 0.9; }
        h3 {font-size: $fontSizeH1 * 0.8; }
        h4 {font-size: $fontSizeH1 * 0.7; }
        h5 {font-size: $fontSizeH1 * 0.6; }
        h6 {font-size: $fontSizeH1 * 0.5; }
    }
}
@media only screen and (max-width: 600px) {
    $fontSizeH1: 26px;
    $emSizeText: $emSize * 1.3;
    .wTxt {
        line-height:  $emSizeText;
        font-size: 13px;
        h1 {font-size: $fontSizeH1; }
        h2 {font-size: $fontSizeH1 * 0.92; }
        h3 {font-size: $fontSizeH1 * 0.84; }
        h4 {font-size: $fontSizeH1 * 0.76; }
        h5 {font-size: $fontSizeH1 * 0.68; }
        h6 {font-size: $fontSizeH1 * 0.6; }
        ul, ol, dl {
            margin-left: $emSize;
        }
        ul, ol, dl, pre, p, table, blockquote, address, .iframeHolder {
            @extend %marginTopBottom;
        }
        ul, ol {
            ul:last-child, ol:last-child {
                @extend %marginTopBottom;
            }
        }
    }
    %marginTopBottom {
        margin-top: $emSizeText;
        margin-bottom: $emSizeText;
    }
}