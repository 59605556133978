/*all_reset.scss*/
@import "../_config";
/*
    reset.css
    wezom noscript.css
    print.css
    Wezom wTPL v4.0.0
*/

/*-------------------------------  Reset  ------------------------------------*/
a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,div,dl,dt,em,embed,fieldset,figcaption,figure,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,nav,object,ol,output,p,pre,q,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video{margin:0;padding:0;border:0;font:inherit;font-size:100%;vertical-align:baseline}
    article,aside,blockquote,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary{display:block}
audio, canvas, video {
    display:inline-block;
}
audio {
    &:not([controls]){
        display:none;height:0;
    }
}
fieldset {
    margin:0 2px;
    padding:$emSizeUn3 $emSizePart $emSizePart;
    border:1px solid $colorMiddleLight;
    legend {
        white-space:normal;
    }
}
embed, img, object{
    max-width:100%;
}
img {
    height:auto;
}
a {
    img {
        border:0;
        cursor:pointer;
        text-decoration:none;
    }
    &:active,&:focus,&:hover,&:link,&:visited {
        outline: 0;
    }
}
b, dt, strong{
    font-weight:700;
}
dfn, em, i {
    font-style:italic;
}
blockquote, q {
    quotes:none;
    font-style:italic;
    &:before, &:after {
        content:'';
        content:none;
    }
}
hr {
    box-sizing:border-box;
    width:100%;
    height:1px;
    margin:$emSizeText 0;
    padding:0;
    border:none;
    background:$colorMiddle;
}
mark {
    color:$colorBlack;
    background:$colorYellow;
}
pre{margin:$emSize 0;
    padding:$emSize;
    border:1px solid $colorMiddleDark;
    border-radius:$borderRadius;
    white-space:pre;
    white-space:pre-wrap;
    background:$colorLight;
    word-wrap:break-word;
}
code, kbd, pre, samp {
    font-family:'courier new',monospace,serif;
    font-size:$emSize;
}
small {
    font-size:80%;
}
big {
    font-size:125%;
}
sub, sup {
    position:relative;
    font-size:75%;
    line-height:0;
    vertical-align:baseline;
}
sup {
    top:-$emSizeHalf;
    left:-$emSizeUn3;
}
sub {
    bottom:-$emSizeUn2;
    left:-$emSizeUn3;
}
abbr, dfn {
    &[title] {
        border-bottom:1px dotted $colorBlack;
        cursor:help;
    }
}
ins, u {
    text-decoration:underline;
}
del, s {
    text-decoration:line-through;
}

dd+dt{margin-top:$emSize;}
dd{margin:0 0 0 $fontSizeH1;}
%listNone {
    list-style:none;
}
nav {
    ol, ul {
        @extend %listNone;
    }
}
ol, ul {
    @extend %listNone;
}
dl, menu {
    margin:$emSize 0 $emSize $emSize;
    @extend %listNone;
}
table{width:100%;border-collapse:collapse;border-spacing:0;}

%valignTop {
    vertical-align:top;
}
td {
    @extend %valignTop;
    img {
        @extend %valignTop;
    }
}

@mixin Selection() {
    text-shadow:none;
    color:$colorWhite;
    background:#2597ff;
}

@mixin uiPB() {
    padding:0;
    border:0;
}
@mixin msHide() {
    display:none;
    width:0;
    height:0;
}
::selection {@include Selection();}
::-moz-selection {@include Selection();}
::-ms-selection{@include Selection();}
[hidden]{display:none}

%appearance {
    appearance:button;
}
%appearanceNone {
    -moz-appearance:none;
    appearance:none;
}
%pointer {
    cursor:pointer;
}
%notallowed {
    cursor: not-allowed;
}
button {
    @extend %allUI;
    @extend %appearance;
    @extend %pointer;
    &[disabled] {
        @extend %notallowed;
    }
    &::-moz-focus-inner {
        @include uiPB();
    }
}
input {
    @extend %allUI;
    &[type="button"],&[type="reset"],&[type="submit"] {
        @extend %appearance;
        @extend %pointer;
    }
    &[type="search"] {
        appearance: textfield;
        box-sizing: content-box;
        &::-webkit-search-cancel-button, &::-webkit-search-decoration {
            @extend %appearanceNone;
        }
    }
    &[type="url"], &[type="tel"], &[type="text"], &[type="email"], &[type="search"], &[type="password"] {
        @extend %textUi;
    }
    &[type="checkbox"], &[type="radio"] {
        box-sizing: border-box;
        padding: 0;
        @extend %pointer;
    }
    &[disabled] {
        @extend %notallowed;
    }
    &::-ms-clear{@include msHide();}
    &::-ms-reveal{@include msHide();}
    &::-moz-focus-inner {
        @include uiPB();
    }
    &:invalid {
        box-shadow:none;
    }
    @extend %inputUiPlacehoder;
}
%allUI {
    margin:0;
    font-family:sans-serif;
    font-size:100%;
    vertical-align:baseline;
    outline:0;
}
textarea{
    @extend %allUI;
    overflow:auto;
    max-width: 100%;
    vertical-align:top;
    @extend %textUi;
    @extend %textUiPlacehoder;
}
select {
    @extend %allUI;
}
%textUi {
    @extend %appearanceNone;
    -webkit-border-radius:0;
    border-radius:0;
    &:focus {
        background:#ffface!important;
    }
}
@mixin placeholderColor() {
    color:#999;
}
@mixin placeholder() {
    &::-webkit-input-placeholder {
        @include placeholderColor();
    }
    &::-moz-placeholder {
        @include placeholderColor();
        opacity: 1;
    }
    &:-ms-input-placeholder {
        @include placeholderColor();
    }
}
%inputUiPlacehoder {
    @include placeholder();
    @extend %UiPlacehoderFocus;
}
%textUiPlacehoder {
    @include placeholder();
    @extend %UiPlacehoderFocus;
}
%UiPlacehoderFocus {
    &:focus {
        &::-webkit-input-placeholder {
            color:transparent;
        }
    }
    &:focus {
        &::-moz-placeholder {
            color:transparent;
        }
    }
    &:focus {
        &:-ms-input-placeholder {
            color:transparent;
        }
    }
}

html, body {
    height:100%;
    margin:0;
    padding:0;
}
html {
    display:block;
    font-family:sans-serif;
    font-size:100%;
    -ms-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
}

/*-------------------------------  NOSCRIPT  ------------------------------------*/
$wzmBDcolor: #ababab;
$wzmBDhover: #ead371;
$wzmBDactive: #beaf6e;
.wzmMsg_Wrapp {
    z-index:9998;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:auto;
    margin:0;
    padding:0 0 3px;
    border-bottom:1px solid $wzmBDcolor;
    overflow:visible;
    font-family:sans-serif;
    font-size:12px;
    font-style:normal;
    font-weight:normal;
    line-height:$emSize * 1.2;
    color:$colorBlack;
    background-color:#fcea9c;
    -webkit-backface-visibility:hidden;
    user-select:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
}
.wzmMsg_Text {
    margin-left:70px;
    padding:0 30px 0 10px;
    min-height:20px;
    border-left:1px solid $wzmBDcolor;
    > p {
        margin:5px 0;
        white-space:normal;
    }
    a {
        text-decoration:underline;
        color:$colorRed;
        &:hover {
            color:#900;
        }
    }
}
.wzmMsg_Link {
    display:block;
    position:absolute;
    top:7px;
    left:10px;
    width:50px;
    height:18px;
    text-decoration:none !important;
    outline:none;
    img {
        border:none;
    }
}
$wzmCube: 26px;
%wzmClose {
    display:block;
    position:absolute;
    top:5px;
    right:5px;
    width:$wzmCube;
    height:$wzmCube;
    text-align:center;
    font-size:22px;
    line-height:$wzmCube;
    cursor:hand;
    cursor:pointer;
}

#wzmMsg_JsClose {
    @extend %wzmClose;
    z-index:9999;
    opacity:0;
    &:checked, &:checked + .wzmMsg_Wrapp {
        display:none;
    }
    &:hover + .wzmMsg_Wrapp .wzmMsg_Close {
        @extend %wzmBDhover;
    }
    &:active + .wzmMsg_Wrapp .wzmMsg_Close {
        @extend %wzmBDactive;
    }
}
.wzmMsg_Close {
    @extend %wzmClose;
    > span {
        display:block;
        position:relative;
        width:$wzmCube;
        height:$wzmCube;
        line-height:$wzmCube;
    }
    &:hover {
        @extend %wzmBDhover;
    }
    &:active {
        @extend %wzmBDactive;
    }
}
%wzmBDhover {
    background-color:$wzmBDhover;
}
%wzmBDactive {
    background-color:$wzmBDactive;
}

/*-------------------------------  Print  ------------------------------------*/
@media print {
    * {
        text-shadow: none!important;
        color: #000!important;
        background: transparent!important;
        box-shadow: none!important;
    }
    a {
        text-decoration: underline;
        &:visited {
            text-decoration: underline;
        }
        &[href]:after {
            content: " ("attr(href) ") ";
        }
        &[href^="javascript:"], &[href^="#"] {
            &:after {
                content: "";
            }
        }
    }
    abbr {
        &[title] {
            &:after {
                content: " ("attr(title) ") ";
            }
        }
    }
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr, img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100%!important;
    }
    @page {margin: 0.5cm; }
    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }
    h2, h3 {
        page-break-after: avoid;
    }
}