/*jquery-validation.scss*/
@import '../_config';
/*
    jquery-validation.css
    Wezom wTPL v3.0
*/
$unitQuadro: 4px;
$unitRadius: $unitQuadro - 1;
/*-------------------------------  validation ------------------------------------*/
    .wForm {
        position: relative;
        .error {
            border-color: $colorDanger !important;
        }
        label {
            cursor: pointer;
            &.error {
                z-index: 3;
                position: absolute;
                top: 100%;
                left: 0;
                padding: $unitQuadro/2 $unitQuadro*2;
                max-width: 100%;
                border: 1px solid $colorDanger;
                text-align: center;
                font-size: $unitQuadro*3-1;
                line-height: $unitQuadro*3+1;
                background: $colorWhite;
                -ms-transform: translateY($unitQuadro);
                transform: translateY($unitQuadro);
                cursor: help;
                &:before, &:after {
                    content: " ";
                    position: absolute;
                    bottom: 100%;
                    left: 10%;
                    width: 0;
                    height: 0;
                    border: solid transparent;
                    pointer-events: none;
                }
                &:before {
                    border-color: rgba($colorDanger, 0);
                    border-bottom-color: $colorDanger;
                    border-width: ($unitQuadro+1);
                    margin-left: -($unitQuadro+1);
                }
                &:after {
                    border-color: rgba($colorWhite, 0);
                    border-bottom-color: $colorWhite;
                    border-width: $unitQuadro;
                    margin-left: -$unitQuadro; 
                }
            }
        }
    }
    %noUserSelect {
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
    }
    .wFileVal, .wCheck, .wRadio {
        @extend %noUserSelect;
    }
/*-------------------------------  wFormDef  ------------------------------------*/
    .wFormDef {
        .wFormRow {
            cursor: default;
            position: relative;
            margin: $unitQuadro*4 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .wFormInput {
            position: relative;
            display: block;
            margin-bottom: $unitQuadro*3;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .wLabel {
            display: block;
            text-transform: uppercase;
            padding: $unitQuadro+1 0;
            margin-left: $unitQuadro - 1;
        }
        %wInput {
            box-sizing: border-box;
            display: block;
            width: 100%;
            padding: $unitQuadro*3;
            margin: 0;
            border: 1px solid #ccc;
            background-color: $colorWhite;
            &:hover {
                background: #f8f8f8;
            }
            &:focus {
                border-color: #5696D0;
                box-shadow: inset 0 0 0 1px #5696D0;
            }
        }
        %wError {
            &.error {
                background-color: #fdd;
                &:focus {
                    @extend %wErrorPsevdoClass;
                }
            }
        }
        %wErrorPsevdoClass {
            border-color: $colorDanger;
            box-shadow: inset 0 0 0 1px $colorDanger;
        }
        .wInput {
            @extend %wInput;
            @extend %wError;
        }
        .wInputSpan {
            @extend %wInput;
            @extend %wError;
            background-color: transparent;
            min-height: $unitQuadro*10;
            line-height: $unitQuadro*4;
        }
        .wTextarea {
            @extend %wInput;
            @extend %wError;
            min-height: $unitQuadro*30;
            max-height: $unitQuadro*80;
        }
        .wSelect {
            @extend %wInput;
            @extend %wError;
        }
        .wFile {
            @extend %wHidden;
            &.error {
                &:active ~ .wFileVal {
                    @extend %wErrorPsevdoClass;
                }
                &:focus ~ .wFileVal {
                    @extend %wErrorPsevdoClass;
                }
                &:hover ~ .wFileVal {
                    @extend %wErrorPsevdoClass;
                }
            }
        }
        .wFileVal {
            > span {
                display: block;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
                > ins {
                    color: #999;
                    text-decoration: none;
                    font-style: italic;
                }
            }
        }
    }
    .wFormDef {
        label.error:not([style$="none;"]) ~ .wFileVal {
            border-color: $colorDanger;
        }
    }
    %wCR {
        @extend %wLinH;
        @extend %wDIB;
        margin: 0 $unitQuadro*2+2 $unitQuadro*2 0;
        input {
            @extend %wHidden;
            @extend %wHiddenCheck;
        }
        span {
            position: relative;
            display: inline;
            @extend %wLinH;
        }
        ins {
            box-sizing: border-box;
            position: relative;
            text-decoration: none;
            height: $unitQuadro*4+2;
            width: $unitQuadro*4+2;
            border-radius: $unitRadius;
            margin-right: $unitQuadro*1.2;
            background-color: $colorDanger;
            box-shadow: inset 0 0 0 1px rgba($colorBlack,.5), inset 0 0 0 $unitQuadro*4 $colorWhite;
            border: 1px solid $colorWhite;
            transition: box-shadow .15s linear, border-color .15s linear;
            @extend %wLinH;
            @extend %wDIB;
        }
    }
    %wLinH {
        line-height: $unitQuadro*4+2;
    }
    %wDIB {
        display: inline-block;
        vertical-align: top;
    }
    %wHidden {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 1px;
        opacity: 0;
        pointer-events: none;
    }
    %wHiddenCheck {
        &:checked {
            ~ ins {
                box-shadow: inset 0 0 0 $unitQuadro $colorWhite;
                border-color: transparent;
            }
        }
    }
    .wFormDef {
        .wCheck {
            @extend %wCR;
        }
        .wRadio {
            @extend %wCR;
            ins {
                border-radius: $unitQuadro*4+2;
            }
        }
        .inpInfo {
            pointer-events: none;
            z-index: 2;
            position: absolute;
            top: 0;
            height: 0;
            overflow: hidden;
            right: 0;
            padding:  $unitQuadro*0.5  $unitQuadro+2;
            text-transform: uppercase;
            font-size: $unitQuadro*2+1;
            line-height: $unitQuadro*3;
            color: $colorWhite;
            border-radius: 0 $unitRadius 0 $unitRadius;
            background: #5696D0;
            opacity: 0;
            transition: opacity .2s ease, height .2s ease;
        }
        .wTextarea,
        .wSelect,
        .wInput {
            &.error ~ .inpInfo {
                background: #f00;
            }
            &:focus ~ .inpInfo {
                height: 12px;
                opacity: 1;
                -webkit-transform: none;
                -moz-transform: none;
                transform: none;
            }
        }
        .wCaption {
            display: block;
            font-size: 12px;
            color: #999;
            font-style: italic;
            line-height: 14px;
            margin-bottom: 5px;
        }
    }

.no-touch {
    .wFormDef {
        .wInput {
            &[type="number"],
            &[type="date"] {
                -moz-appearance: textfield;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -moz-appearance:none;
                    appearance:none;
                    -webkit-appearance:none;
                }
            }
        }
    }
}