/*jquery-magnific-popup.scss*/
/*
    jquery-magnific-popup.css
    Wezom wTPL v3.0
*/

/*-------------------------------  Magnific  ------------------------------------*/
    /* Magnific Popup CSS */
    .mfp-bg{z-index:1042;position:fixed;top:0;left:0;width:100%;height:100%;overflow:hidden;opacity:0.8;background:#0b0b0b;filter:alpha(opacity=80);}
    .mfp-wrap{z-index:1043;position:fixed;top:0;left:0;width:100%;height:100%;outline:none !important;-webkit-backface-visibility:hidden;}
    .mfp-container{position:absolute;top:0;left:0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;width:100%;height:100%;padding:0 8px;text-align:center;}
    .mfp-container:before{content:'';display:inline-block;height:100%;vertical-align:middle;}
    .mfp-align-top .mfp-container:before{display:none;}
    .mfp-content{display:inline-block;z-index:1045;position:relative;margin:0 auto;text-align:left;vertical-align:middle;}
    .mfp-inline-holder .mfp-content,.mfp-ajax-holder .mfp-content{width:100%;cursor:auto;}
    .mfp-ajax-cur{cursor:progress;}
    .mfp-zoom-out-cur,.mfp-zoom-out-cur .mfp-image-holder .mfp-close{cursor:-moz-zoom-out;cursor:-webkit-zoom-out;cursor:zoom-out;}
    .mfp-zoom{cursor:pointer;cursor:-webkit-zoom-in;cursor:-moz-zoom-in;cursor:zoom-in;}
    .mfp-auto-cursor .mfp-content{cursor:auto;}
    .mfp-close,.mfp-arrow,.mfp-preloader,.mfp-counter{user-select:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;}
    .mfp-loading.mfp-figure{display:none;}
    .mfp-hide{display:none !important;}
    .mfp-preloader{z-index:1044;position:absolute;top:50%;right:8px;left:8px;width:auto;margin-top:-0.8em;text-align:center;color:#cccccc;}
    .mfp-preloader a{color:#cccccc;}
    .mfp-preloader a:hover{color:white;}
    .mfp-s-ready .mfp-preloader{display:none;}
    .mfp-s-error .mfp-content{display:none;}
    button.mfp-close,button.mfp-arrow{display:block;z-index:1046;-webkit-appearance:none;-moz-appearance:none;appearance:none;padding:0;border:0;overflow:visible;outline:none;background:transparent;-webkit-box-shadow:none;box-shadow:none;cursor:pointer;}
    button::-moz-focus-inner{padding:0;border:0;}
    .mfp-close{position:absolute;top:0;right:0;width:44px;height:44px;padding:0 0 18px 10px;text-align:center;text-decoration:none;font-family:Arial,Baskerville,monospace;font-size:28px;font-style:normal;line-height:44px;color:white;opacity:0.65;filter:alpha(opacity=65);}
    .mfp-close:hover,.mfp-close:focus{opacity:1;filter:alpha(opacity=100);}
    .mfp-close:active{top:1px;}
    .mfp-close-btn-in .mfp-close{color:#333333;}
    .mfp-image-holder .mfp-close,.mfp-iframe-holder .mfp-close{right:-6px;width:100%;padding-right:6px;text-align:right;color:white;}
    .mfp-counter{position:absolute;top:0;right:0;font-size:12px;line-height:18px;color:#cccccc;}
    .mfp-arrow{position:absolute;top:50%;width:90px;height:110px;margin:0;margin-top:-55px;padding:0;opacity:0.65;filter:alpha(opacity=65);-webkit-tap-highlight-color:rgba(0,0,0,0);}
    .mfp-arrow:active{margin-top:-54px;}
    .mfp-arrow:hover,.mfp-arrow:focus{opacity:1;filter:alpha(opacity=100);}
    .mfp-arrow:before,.mfp-arrow:after,.mfp-arrow .mfp-b,.mfp-arrow .mfp-a{content:'';display:block;position:absolute;top:0;left:0;width:0;height:0;margin-top:35px;margin-left:35px;border:medium inset transparent;}
    .mfp-arrow:after,.mfp-arrow .mfp-a{top:8px;border-top-width:13px;border-bottom-width:13px;}
    .mfp-arrow:before,.mfp-arrow .mfp-b{border-top-width:21px;border-bottom-width:21px;opacity:0.7;}
    .mfp-arrow-left{left:0;}
    .mfp-arrow-left:after,.mfp-arrow-left .mfp-a{margin-left:31px;border-right:17px solid white;}
    .mfp-arrow-left:before,.mfp-arrow-left .mfp-b{margin-left:25px;border-right:27px solid #3f3f3f;}
    .mfp-arrow-right{right:0;}
    .mfp-arrow-right:after,.mfp-arrow-right .mfp-a{margin-left:39px;border-left:17px solid white;}
    .mfp-arrow-right:before,.mfp-arrow-right .mfp-b{border-left:27px solid #3f3f3f;}
    .mfp-iframe-holder{padding-top:40px;padding-bottom:40px;}
    .mfp-iframe-holder .mfp-content{width:100%;max-width:900px;line-height:0;}
    .mfp-iframe-holder .mfp-close{top:-40px;}
    .mfp-iframe-scaler{width:100%;height:0;padding-top:56.25%;overflow:hidden;}
    .mfp-iframe-scaler iframe{display:block;position:absolute;top:0;left:0;width:100%;height:100%;background:black;box-shadow:0 0 8px rgba(0,0,0,0.6);}
    /* Main image in popup */
    img.mfp-img{display:block;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;width:auto;height:auto;margin:0 auto;padding:40px 0 40px;max-width:100%;line-height:0;}
    /* The shadow behind the image */
    .mfp-figure{line-height:0;}
    .mfp-figure:after{content:'';display:block;z-index:-1;position:absolute;top:40px;right:0;bottom:40px;left:0;width:auto;height:auto;background:#444444;box-shadow:0 0 8px rgba(0,0,0,0.6);}
    .mfp-figure small{display:block;font-size:12px;line-height:14px;color:#bdbdbd;}
    .mfp-figure figure{margin:0;}
    .mfp-bottom-bar{position:absolute;top:100%;left:0;width:100%;margin-top:-36px;cursor:auto;}
    .mfp-title{padding-right:36px;text-align:left;line-height:18px;color:#f3f3f3;word-wrap:break-word;}
    .mfp-image-holder .mfp-content{max-width:100%;}
    .mfp-gallery .mfp-image-holder .mfp-figure{cursor:pointer;}
    @media screen and (max-width:800px) and (orientation:landscape), screen and (max-height:300px){
        .mfp-img-mobile .mfp-image-holder{padding-right:0;padding-left:0;}
        .mfp-img-mobile img.mfp-img{padding:0;}
        .mfp-img-mobile .mfp-figure:after{top:0;bottom:0;}
        .mfp-img-mobile .mfp-figure small{display:inline;margin-left:5px;}
        .mfp-img-mobile .mfp-bottom-bar{position:fixed;top:auto;bottom:0;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;margin:0;padding:3px 5px;background:rgba(0,0,0,0.6);}
        .mfp-img-mobile .mfp-bottom-bar:empty{padding:0;}
        .mfp-img-mobile .mfp-counter{top:3px;right:5px;}
        .mfp-img-mobile .mfp-close{position:fixed;top:0;right:0;width:35px;height:35px;padding:0;text-align:center;line-height:35px;background:rgba(0,0,0,0.6);}
    }
    @media all and (max-width:900px){
        .mfp-arrow{-webkit-transform:scale(0.75);transform:scale(0.75);}
        .mfp-arrow-left{-webkit-transform-origin:0;transform-origin:0;}
        .mfp-arrow-right{-webkit-transform-origin:100%;transform-origin:100%;}
        .mfp-container{padding-right:6px;padding-left:6px;}
    }
    .mfp-ie7 .mfp-img{padding:0;}.mfp-ie7 .mfp-bottom-bar{left:50%;width:600px;margin-top:5px;margin-left:-300px;padding-bottom:5px;}.mfp-ie7 .mfp-container{padding:0;}.mfp-ie7 .mfp-content{padding-top:44px;}.mfp-ie7 .mfp-close{top:0;right:0;padding-top:0;}
    .zoom-in .mfp-preloader,
    .zoom-in .mfp-arrow,
    .zoom-in .mfp-content {
        opacity:0;
        -webkit-transition:all 0.4s ease-in-out; 
        -moz-transition:all 0.4s ease-in-out; 
        -o-transition:all 0.4s ease-in-out; 
        transition:all 0.4s ease-in-out;
        -webkit-transform:scale(0.8); 
        -moz-transform:scale(0.8); 
        -ms-transform:scale(0.8); 
        -o-transform:scale(0.8); 
        transform:scale(0.8); 
    }
    /* animate in */
        .zoom-in.mfp-ready .mfp-preloader,
        .zoom-in.mfp-ready .mfp-arrow,
        .zoom-in.mfp-ready .mfp-content {
            opacity:1;
            transition-timing-function: cubic-bezier(0.63, 0.55, 0.31, 1.5);
            -webkit-transform:scale(1); 
            -moz-transform:scale(1); 
            -ms-transform:scale(1); 
            -o-transform:scale(1); 
            transform:scale(1); 
        }
    /* animate out */
        .zoom-in.mfp-removing .mfp-preloader,
        .zoom-in.mfp-removing .mfp-arrow,
        .zoom-in.mfp-removing .mfp-content {
            -webkit-transform:scale(0.8); 
            -moz-transform:scale(0.8); 
            -ms-transform:scale(0.8); 
            -o-transform:scale(0.8); 
            transform:scale(0.8);
            opacity:0;
        }
    /* Dark overlay, start state */
        .zoom-in.mfp-bg {
            opacity:0.001; /* Chrome opacity transition bug */
            -webkit-transition:opacity 0.3s ease-out; 
            -moz-transition:opacity 0.3s ease-out; 
            -o-transition:opacity 0.3s ease-out; 
            transition:opacity 0.3s ease-out;
        }
    /* animate in */
        .zoom-in.mfp-ready.mfp-bg{opacity:0.8;}
    /* animate out */
        .zoom-in.mfp-removing.mfp-bg{opacity:0;}
        .mfp-image > * { cursor: pointer;}
    /* mfiModal */
        .mfiModal {
            position: relative;
            width: 90%;
            max-width: 320px;
            margin-left: auto;
            margin-right: auto;
            padding: 30px;
            background: #fff;
        }
        .mfiModal.medium {
            max-width: 500px;
        }
        .mfiModal.big {
            max-width: 720px;
        }