/*enterReg.scss*/
/*-------------------------------  enterPopup  ------------------------------------*/
.enterRegPopup {
    position: relative;
    width: auto;
    margin: 20px auto;
    padding: 1.5% 2%;
    max-width: 700px;
    background: #fff;
}

.enterReg_top {
    position: relative;
    margin-bottom: 3%;
    overflow: hidden;
}

.enterReg_top:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background: #ddd;
}

.enterBlock, .regBlock {
    z-index: 9;
    float: left;
    position: relative;
    width: 46%;
    -webkit-backface-visibility: hidden;
}

.regBlock {
    float: right;
}

.enterReg_top .erTitle {
    position: relative;
    margin-bottom: 1.5em;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #000;
}

.enterBlock_form {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    -webkit-transition: opacity 0.8s ease;
    -moz-transition: opacity 0.8s ease;
    transition: opacity 0.8s ease;
}

.enterBlock .visForm {
    opacity: 1;
    visibility: visible;
    position: relative;
    transform: none;
}

.wFormRow {
    position: relative;
    margin-bottom: 8%;
}

.wFormRow input {
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 10px 20px;
    border: 1px solid #ddcbd6;
    font-size: 14px;
    color: #000;
    background: #fff;
    -webkit-backface-visibility: hidden;
}

.wFormRow input:focus {
    border-color: #000;
}

.wFormRow input:focus::-webkit-input-placeholder {
    color: transparent;
}

.wFormRow input:focus::-moz-placeholder {
    color: transparent;
}

.wFormRow input:focus::-ms-placeholder {
    color: transparent;
}

.wFormRow .inpInfo {
    z-index: 2;
    position: absolute;
    top: 0;
    height: 0;
    overflow: hidden;
    right: 0;
    padding: 2px 5px;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 12px;
    color: #fff;
    background: #000;
    opacity: 0;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.wFormRow input:focus ~ .inpInfo {
    height: 12px;
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
}

.checkBlock {
    display: inline-block;
    position: relative;
    margin-bottom: 7%;
    vertical-align: top;
    cursor: pointer;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
}

.checkBlock input {
    height: 1px;
    width: 1px;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.checkBlock ins {
    float: left;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border: 1px solid #ddcbd6;
    background: #fff;
}

input.error ~ ins {
    border-color: #f00;
}

.checkBlock span {
    display: block;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    color: #a0a0a0;
}

.checkBlock input:checked ~ ins {
    background: #000;
    box-shadow: inset 0 0 0 3px #fff;
}

.checkBlock:hover p {
    color: #333;
}

.enterReg_ctrl {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.enterReg_btn {
    display: inline-block;
    vertical-align: top;
    background: #000;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    height: 36px;
    padding: 0 30px;
    border: none;
    text-transform: uppercase;
}

.enterReg_btn:hover {
    background: #666;
}

.enterReg_btn:active {
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    transform: translateY(2px);
    box-shadow:  inset 0 -2px 4px #fff, inset 0 3px 6px rgba(0,0,0,0.8);
    background: #666;
}

.passLink {
    position: absolute;
    left: 0;
    bottom: 7px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
}

.passLink:hover {
    text-decoration: underline;
}

.forgetInf {
    font-size: 14px;
    line-height: 1.5em;
    color: #999;
    min-height: 116px;
}

.socEnter {
    padding: 3% 0 0;
    border-top: 1px solid #ddd;
    text-align: center;
}

.socEnter .erTitle {
    display: inline-block;
    vertical-align: top;
    font-size: 15px;
    line-height: 30px;
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
}

.socLinkEnter {
    display: inline-block;
    vertical-align: top;
    margin-left: 2%;
}

.socLinkEnter button {
    display: inline-block;
    vertical-align: top;
    border: none;
    width: 80px;
    height: 30px;
    margin-left: 10px;
    background-color: #000;
}

.socLinkEnter button:hover {
    background-color: #666;
}

.socLinkEnter button:active {
    background-color: #666;
    box-shadow: inset 0 -1px 3px rgba(255,255,255,0.8), inset 0 3px 6px rgba(0,0,0,0.4);
    -webkit-transform: translateY(1px);
    -moz-transform: translateY(1px);
    transform: translateY(1px);
}

.eVk {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAYAAAByF90EAAABa0lEQVQ4y5VSuU7DQBBdUVAgpUCIGvEhCBD8BRF/gCgoEX9AFyhSIkpER41yGBkfEJICBeJ1YrDIgeNcxE4IzrCTYGsdU8BIq7Hf83tzrElWpedpiUp4bh8qexs7CYInJZXcwXAEGN2eCzwHMF7KqvoF015OjkLPyHO5AX447iemBfy42XJWxuOAgoxCBd8oLWt3wAV9sYCIucohD2IFX/DRHwY4E4vTbmC+1x/wEtZteZ9s7Z6S2ns3AFsdB1MMRR02EtfRFWIsZ2YKA+KTyjf3lWOeZNXNbVZg9OVxRppQKJrrPNZs9zEtB0ab8RNCDSvU7pNeD73bTGRPhaGR/DUQ/0EpGHF+uX+JQrG6FjGa3Iakmf8xqltdaNjt1YgR4+YetdqvItxHRqbXnhdu26y1MS2GjH7MYmzMiBG7gBLygqolZ7mUpHkRo+nyE0TMGwdMDK/VFrzVOyDm9COfF1Q9iWPhb4OcnDfgG1JjZ+6XlDL9AAAAAElFTkSuQmCC) center center no-repeat;
}

.eFb {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAXCAYAAAA/ZK6/AAAAvUlEQVQ4y2NwiJ7MgIwdYyYzHD9/v/LgqTufrt5+/v/mvZf/z119/P/gyduvjl98WIymeArDgZO3/+MCQIP+o2gACpT+xwMwNACtfYOu6P3Hb2BnXbj25D/QmedRNIDcigxAitwSpqGoQeFcuvEMRcOh03e3oQcKIQ0bMDR8+PBF9uDJO09A+O2Hrygart958R8mB8JAIX6GyzeeWv8nErx6912RDhpI9vSohpGoAZiEX4CyIwwfPXe/C10DAOix0CKv56WjAAAAAElFTkSuQmCC) center center no-repeat;
}

.eOd {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAABGKffQAAAByElEQVQ4y4WSyy8DQRzHJ+LkPxAnNxEnh14aidKrq4t63MXJUcRBXBx1ESdBHJs04iBxsX2E7vblUW2zz5ZtWc0qKtS2HTNbM7aNxy+ZmWTm83t95weGJ7wAL90o97KcWCgZFWjW6jBfMGCAlw4hhB2EsbbLjOZ8fnmDP1lGvsdHlwWPTq2DnGbQx+pHDWp3ZdhoNOgdGxFVC+aS6gy5xKlfns0e/BC/zo+9V00aAFknCEalfQKHeGmb1IdXgBNp9ItMcQiE4/IaTceJCTucEooUlotGPxBVfYDUZ5o1rIAfl8ZGBJ2ABf0JujxfaqAGbuEfdpqQF6l07ukNcHImvLZDuDF7HwCLfilpTve013me1VwBXjxiOSmNG9/zRx2e+R1HWrlzuDwMoBoL6gMMxxRmZfOYmVv2Md7dAIPUOCo9VkjzKUC0/M/4ixwEaPNIudKvEBYqFJWhUiz1WYVjWfjUzfiDUWkBY1c3MKvqgy2DhGGkq9wetV5vINnUBQo3QVG1p802J+3bIaYsWTCS6qCtvq2RSQacRATT7oAHC7CcUCYgnhOS0nKwfVQ4rqyC90q1m+VF/iypzNqHiDgEeWkHfZDPjeb+E4VugWfRpPpQAAAAAElFTkSuQmCC) center center no-repeat;
}

.eMr {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAQAAADIQr64AAABzElEQVQ4EQXBS2gOAAAH8P/Htu+xr8WBj8mjkJhxlORx8AiFyUFclCKHKSQl4UCjSTkoSpQU5SzFQTSKODgsLI9YIvKc8W2mn98vKqqqKkaLRpPN1aZNm0kaRUFRWUlJKarGaBYNFjvpqT8GDRjwWLelGkRRs7JyNKuImvOGwV+D6oBhF7SKJmXlqIi5ekGfQ1ZZYIktzvoEXpgnisoRMz3DiG4VUdNumoiavb6gzyxRiIproFPMdtVX/HHXOjHOIUM4rySxGpwRa9XBa9/xQ59X5tkCVkicQ68xZurHTUtMNN9Jv8BO8QDdEs9xSlxEj1YREbtBlziB+6rx0T9bjfMOu0RERM1DdIld6FeLD+pWm2EIa0RERJPLOCG2GjZkTnz2V4epfmKjiIgY6za6xD58MCVe4qC4hUsaRURsBsfEKTzUEpdxXywHR7UoKOvwBmwSj3HB6OgAh8Ue8MR1dzCsjk471LFBosUN/LNZrPcM8NZ2x/FbHVdVJaLdOww6oigW6rTJeDFBD3hkskhELPMePHLAYlNMt80V67Xa74BJosmoKIiY7jrgl34fjeCeiogoaVaOKCiIRouc9tQ333w1oMdKEQ2KSkpK/wHc9mRWfKCvrwAAAABJRU5ErkJggg==) center center no-repeat;
}


@media only screen and (max-width:720px) {
    .popupContent {
        display: block;
        overflow: hidden;
        margin: 0;
        max-height: 0;
        padding: 0 10px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        transition: all 0.5s linear;
    }
    .enterRegPopup {
        max-width: 380px;
        padding: 30px 15px 15px;
    }
    .enterRegPopup .mfp-close {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }
    .enterReg_top {
        margin: 0;
    }
    .enterReg_top:after {
        display: none;
    }
    .socEnter {
        padding: 0;
        border: 0;
    }
    .enterBlock, .regBlock {
        float: none;
        width: auto;
    }
    .enterReg_top .erTitle, .socEnter .erTitle {
        margin: 0;
        background: #000;
        color: #fff;
        display: block;
        text-align: center;
        padding: 10px 0;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        margin-bottom: 1px;
        cursor: pointer;
    }
    .wCur .popupContent {
        max-height: 400px;
        padding: 10px;
    }
    .wCur .erTitle {
        box-shadow: inset 0 0 0 1px #000;
        background: #fff;
        color: #000;
    }
    .socLinkEnter button {
        width: 48%;
        float: left;
        margin: 1%;
        height: 40px;
    }
}