/*wBtn.scss*/
@import "../_config";
$lineHeight: $emSize * 2 + $unit * 2;

$boxShadowBtn: inset 0 (-$unit * 2) $unit * 3 rgba($colorWhite, .45), inset 0 $unit * 2 $unit * 3 rgba($colorBlack, .35), 0 $unit * 2 0;

$height: $lineHeight - $unit;
$padding: $emSize + $unit;

$svgHeight: $emSize + $unit * 1.5;
$svgWidth: $svgHeight;
$svgMargin: -$unit $emSizeHalf - $unit 0 0;

$borderRadius: $emSize * 0.4;


@mixin Shadow($shadow) {
    box-shadow: $boxShadowBtn $shadow;
}

@mixin Button($a, $t) {
    background: #cb2228;
    box-shadow: 6px 8px 20px rgba(0,0,0,0.25);
    &:hover {
        color: #fff;
        background: #df2b31;
    }
    &:active {
        -webkit-transform: translate(0,3px);
        transform: translate(0,3px);
        box-shadow: 3px 3px 5px rgba(0,0,0,0.25);
    }
}

.wBtn {
    text-align: center;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 300px;
    font-size: $fontSize;
    height: 60px;
    line-height: 60px;
    border: none;
    -webkit-transition: background 0.3s ease, -webkit-transform 0.2s ease;
    transition: background 0.3s ease, transform 0.2s ease;
    margin: 0 0 $emSize / 2.5;
    cursor: pointer;
    color: #fff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    @include Button($colorDefault, $colorDark);
    > .svgHolder {
        margin: $svgMargin;
        text-decoration: none !important;
        display: inline-block;
        vertical-align: middle;
        height: $svgHeight;
        width: $svgWidth;
        > svg {fill: $colorMiddleDark; }
    }
    &.w_block {
        display: block;
        width: auto;
        margin-left: 0;
        margin-right: 0;
    }
    &.w_rightIcon {
        > .svgHolder {
            float: right;
            margin: $emSizeHalf + $unit 0 0 $emSizeHalf - $unit;
        }
    }
    &:hover, &:active {
        > .svgHolder > svg {@extend %wBtnSvg; }
    }
    &.w_primary {
        @extend %wBtnStatus;
        @include Button($colorPrimary, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_success {
        @extend %wBtnStatus;
        @include Button($colorSucces, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_info {
        @extend %wBtnStatus;
        @include Button($colorInfo, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_warning {
        @extend %wBtnStatus;
        @include Button($colorWarning, $colorWhite);
        &:hover, &:active {
            > .svgHolder > svg {@extend %wBtnSvgWhite; }
        }
    }
    &.w_transparent {
        background: #f6f6f6;
        width: 210px;
        height: 50px;
        line-height: 48px;
        border: 1px solid #d9dadd;
        box-sizing: border-box;
        box-shadow: none;
        margin-bottom: 15px;
        span {
            color: #000;
            font-size: 18px;
            line-height: 50px;
            text-transform: uppercase;
            font-family: 'PFDinTextProBold', Arial, Helvetica, sans-serif;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
        &:hover {
            background: #df2b31;
            span {
                color: #fff;
            }
        }
    }
    &.w_white {
        margin: 0 auto;
        width: 210px;
        background: #fff;
        line-height: 50px;
        height: 50px;
        display: block;
        span {
            color: #8c8c8c;
            font-size: 16px;
            line-height: 50px;
            letter-spacing: 1px;
            text-transform: uppercase;
            font-family: 'PFDinTextProBold', Arial, Helvetica, sans-serif;
        }
    }
}
%wBtnSvg {fill: $colorDark;}
%wBtnStatus {
    color: $colorWhite;
    text-shadow: 0 1px 3px rgba($colorBlack,.3);
    > .svgHolder > svg {fill: $colorWhite;}
}
%wBtnSvgWhite {fill: $colorWhite;}