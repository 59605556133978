/*wSitemap.scss*/
@import "../_config";
%mapElement {
    padding: $unit $emSizePart;
    border-radius: $borderRadius;
}

%mapElementHover {
    color: $colorWhite;
    text-shadow: $textShadowDark;
}

%mapElementTransition {
    transition: background-color 0.1s ease, color 0.1s ease;
}

.wSitemap {
    font-family: $fontFamily;
    font-size: $fontSize;
    margin: 0 0 $emSizeText;
    > ul {
        padding-left: $emSizeDouble;
        overflow: hidden;
    }
    ul {
        counter-reset: sitemap;
        list-style-type: none;
        ul {
            margin-left: $emSizeDouble;
        }
        li {
            position: relative;
            margin: $emSizeHalf 0;
            &:before {
                @extend %mapElement;
                @extend %mapElementTransition;
                float: left;
                background-color: $colorMiddleLight;
                color: $colorMiddleDark;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                counter-increment: sitemap;
                content: counters(sitemap,".") " ";
            }
            &:after {
                @extend %mapElementTransition;
                content: '|-';
                position: absolute;
                right: 100%;
                margin-right: $emSizeHalf;
                top: 0;
                width: auto;
                height: 100%;
                white-space: nowrap;
                text-align: right;
                opacity: .3;
                border-bottom-left-radius: $borderRadius * 2;
            }
            a {
                @extend %mapElement;
                @extend %mapElementTransition;
                text-decoration: none;
                color: $colorMiddleDark;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                text-shadow: 0 -1px $colorWhite;
                background-color: $colorLight;
                display: block;
                overflow: hidden;
            }
            &:hover {
                > a {
                    @extend %mapElementHover;
                    text-decoration: underline;
                    background-color: darken($colorPrimary, 14%);
                }
                &:before {@extend %mapElementHover; }
                &:after {color: transparent;}
                &:before, &:after {background-color: darken($colorDanger, 14%);}
            }
            li {
                &:hover {
                    > a {background-color: darken($colorPrimary, 7%); }
                    &:before, &:after {background-color: darken($colorDanger, 7%);}
                }
                li {
                    &:hover {
                        > a {background-color: $colorPrimary; }
                        &:before, &:after {background-color: $colorDanger;}
                    }
                    li {
                        &:hover {
                            > a {background-color: lighten($colorPrimary, 7%); }
                            &:before, &:after {background-color: lighten($colorDanger, 7%); }
                        }
                        li {
                            &:hover {
                                > a {background-color: lighten($colorPrimary, 14%); }
                                &:before, &:after {background-color: lighten($colorDanger, 14%); }
                            }
                        }
                    }
                }
            }
        }
    }
}